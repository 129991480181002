import * as React from 'react';
import { __ } from 'react-i18n';
import styled, { withTheme } from 'styled-components';
import { rem } from 'polished';
import {
  FlexRowCenterVertical,
  FlexCol,
  FlexRow,
  FlexColCenter,
  Card,
  ProductTabSmall,
  Chooser,
  IconImg,
  ZemplinLoaderWrapper,
  NoItemsWrapper,
} from 'eshop-defaults';
import { resolveCategoryUrl } from 'eshop-defaults/lib/utilities/selectors';
import API, { ThenArg } from '../../services/API';
import MetaTags from '../_helpers/MetaTags/MetaTags';
import { returnColorBasedOnCategoryName, prop } from '../../utilities';
import { getImagePath } from '../../utilities/product';
import SliderComponent from '../_helpers/Slider/Slider';
import { LoaderWrapper } from 'eshop-defaults/lib/components/Zemplin/General/LoaderWrapper';
import { ZemplinSlider } from 'eshop-defaults';
import { Link } from 'react-router';
import {
  loadHomeNewProducts,
  loadHomeSaleProducts,
} from '../../containers/Home/actions';
import { useState } from 'react';
import IconsBanner from './IconsBanner';
import SecondIconsBanner from './SecondIconsBanner';
import {
  setBreadCrumbPath,
  BreadCrumbType,
} from '../../containers/BreadCrumb/breadCrumbSlice';
import ReactGA from 'react-ga';
import TrackViewItemList from '../Analytics/TrackViewItemList';
import { trackSelectProduct } from '../../utilities/gtm';

interface Props {
  dispatch: any;
  phone?: string;
  theme: any;
  categories: ThenArg<typeof API.loadTree>;
  productCategoriesIsFetching: boolean;
  addToCart: any;
  addToRequest: any;
  newProducts: {
    productsData: ThenArg<typeof API.searchProducts>;
    isFetching: false;
  };
  saleProducts: {
    productsData: ThenArg<typeof API.searchProducts>;
    isFetching: false;
  };
  firstCategoryId: number;
  user: any;
  cart: any;
  firstBenefits: any[];
  secondBenefits: any[];
  banners: ThenArg<typeof API.loadBanners>['banners'];
}

function Home(props: Props) {
  const { firstCategoryId, dispatch } = props;
  const [newsCategoryId, setNewsCategoryId] = useState(
    firstCategoryId || firstCategoryId === 0 ? firstCategoryId : 1,
  );
  const [saleCategoryId, setSaleCategoryId] = useState(
    firstCategoryId || firstCategoryId === 0 ? firstCategoryId : 1,
  );

  const cartItems = prop(props.cart, 'items', []);
  const cartItemsIds: any = cartItems
    ? cartItems.map(c => prop(c, 'good.good_id'))
    : [];

  React.useEffect(() => {
    dispatch(setBreadCrumbPath(BreadCrumbType.NONE));
  }, [dispatch]);

  const changeCurrentNewsIndex = id => {
    setNewsCategoryId(id);
    dispatch(loadHomeNewProducts(id));
  };

  const changeCurrentSaleIndex = id => {
    setSaleCategoryId(id);
    dispatch(loadHomeSaleProducts(id));
  };

  const googleAnalyticsClick = (name: string): void => {
    ReactGA.event({
      category: 'Banner',
      action: 'click',
      label: name,
    });
  };

  const renderCategories = () => {
    const { theme, categories, productCategoriesIsFetching } = props;

    if (productCategoriesIsFetching || productCategoriesIsFetching === null) {
      return <ZemplinLoaderWrapper height={380} />;
    }

    return (
      <CategoryCardsWrapper>
        {categories &&
          categories.map(category => {
            const { category_name, category_id, url, image } = category;
            const { primary, secondary } = returnColorBasedOnCategoryName(
              category_name,
              theme,
            );
            return (
              <Card
                key={category_id}
                imageSrc={
                  image
                    ? getImagePath(image, { width: 284, height: 194 })
                    : '/images/zemplin/placeholder.svg'
                }
                imageMobileSrc={
                  image
                    ? getImagePath(
                        image,
                        { width: 284, height: 120 },
                        false,
                        false,
                        true,
                      )
                    : '/images/zemplin/placeholder.svg'
                }
                url={resolveCategoryUrl(category_id, url)}
                title={category_name}
                alt={`Obrázok ku ${category_name}`}
                buttonColor={primary}
                bgColor={secondary}
                clickHandle={googleAnalyticsClick}
              />
            );
          })}
      </CategoryCardsWrapper>
    );
  };

  const renderFirstInfoBanner = () => {
    const { firstBenefits } = props;
    return <IconsBanner benefits={firstBenefits} />;
  };

  const renderSecondInfoBanner = () => {
    const { secondBenefits } = props;
    return <SecondIconsBanner benefits={secondBenefits} />;
  };

  const renderNewProducts = () => {
    const {
      addToCart,
      addToRequest,
      newProducts,
      categories,
      productCategoriesIsFetching,
      user,
    } = props;
    const isFetching = prop(newProducts, 'isFetching');

    if (
      productCategoriesIsFetching ||
      (!productCategoriesIsFetching &&
        !isFetching &&
        !newProducts.productsData &&
        !categories)
    ) {
      return <LoaderWrapper height={270} />;
    }

    const productsData = prop(newProducts, 'productsData', {});
    const products: ThenArg<
      typeof API.searchProducts
    >['products'] = productsData
      ? prop(productsData[newsCategoryId], 'products', [])
      : [];

    return (
      <NewProductsWrapper>
        <ProductTitleWrapper>
          <NewProductsImageWrapper>
            <IconImg
              width={20}
              height={19}
              src={'/images/zemplin/star.svg'}
              alt="hviezda"
            />
          </NewProductsImageWrapper>
          <Title to={'/novinky'}>{__('Novinky')}</Title>
        </ProductTitleWrapper>
        <ProductCategoryWrapper>
          <Chooser
            items={categories}
            currentId={newsCategoryId}
            onItemClick={changeCurrentNewsIndex}
          />
          {products && products.length > 0 && (
            <TrackViewItemList
              products={products}
              itemListName={__('Novinky homepage')}
              listId={newsCategoryId}
              key={newsCategoryId}
            />
          )}
          <ProductWrapper>
            {isFetching ? (
              <LoaderWrapper height={150} />
            ) : products && products.length > 0 ? (
              products.map((p: any, idx) => {
                const isInCart = cartItemsIds.includes(
                  prop(p, 'main_good.good_id'),
                );
                return (
                  <ProductTabSmall
                    key={p.product_id}
                    // tslint:disable-next-line:jsx-no-lambda
                    addToCart={() => addToCart(p, __('Novinky homepage'))}
                    // tslint:disable-next-line:jsx-no-lambda
                    addToRequest={() => addToRequest(p, __('Novinky homepage'))}
                    product={p}
                    user={props.user}
                    isInCart={isInCart}
                    onClick={() =>
                      trackSelectProduct(p, __('Novinky homepage'), idx)
                    }
                  />
                );
              })
            ) : (
              <NoItemsWrapper
                text={'Kategória neobsahuje žiadne nové produkty'}
                justify={'center'}
              />
            )}
          </ProductWrapper>
          <MobileProductWrapper>
            <SliderComponent
              user={user}
              addToCart={addToCart}
              addToRequest={addToRequest}
              type="ebook"
              slides={products}
              cartItemsIds={cartItemsIds}
            />
          </MobileProductWrapper>
        </ProductCategoryWrapper>
      </NewProductsWrapper>
    );
  };

  const renderSaleProducts = () => {
    const {
      addToCart,
      addToRequest,
      saleProducts,
      productCategoriesIsFetching,
      categories,
      user,
    } = props;
    const isFetching = prop(saleProducts, 'isFetching');

    if (
      productCategoriesIsFetching ||
      (!productCategoriesIsFetching &&
        !isFetching &&
        !saleProducts.productsData &&
        !categories)
    ) {
      return <LoaderWrapper height={270} />;
    }

    const productsData = prop(saleProducts, 'productsData', {});
    const products: ThenArg<
      typeof API.searchProducts
    >['products'] = productsData
      ? prop(productsData[saleCategoryId], 'products', [])
      : [];

    return (
      <>
        <ProductTitleWrapper>
          <SaleProductsImageWrapper>
            <IconImg
              width={16}
              height={16}
              src={'/images/zemplin/percent.svg'}
              alt="percenta"
            />
          </SaleProductsImageWrapper>
          <Title to={'/vypredaj'}>{__('Výpredaj')}</Title>
        </ProductTitleWrapper>
        <ProductCategoryWrapper>
          <Chooser
            items={categories}
            currentId={saleCategoryId}
            onItemClick={changeCurrentSaleIndex}
          />
          {products && products.length > 0 && (
            <TrackViewItemList
              products={products}
              itemListName={__('Výpredaj homepage')}
              listId={saleCategoryId}
              key={saleCategoryId}
            />
          )}
          <ProductWrapper>
            {isFetching ? (
              <LoaderWrapper height={150} />
            ) : products && products.length > 0 ? (
              products.map((p: any, idx) => {
                const isInCart = cartItemsIds.includes(
                  prop(p, 'main_good.good_id'),
                );
                return (
                  <ProductTabSmall
                    key={p.product_id}
                    // tslint:disable-next-line:jsx-no-lambda
                    addToCart={() => addToCart(p, __('Výpredaj homepage'))}
                    // tslint:disable-next-line:jsx-no-lambda
                    addToRequest={() =>
                      addToRequest(p, __('Výpredaj homepage'))
                    }
                    product={p}
                    user={props.user}
                    isInCart={isInCart}
                    onClick={() =>
                      trackSelectProduct(p, __('Výpredaj homepage'), idx)
                    }
                  />
                );
              })
            ) : (
              <NoItemsWrapper
                text={'Kategória neobsahuje žiadne zľavnené produkty'}
                justify={'center'}
              />
            )}
          </ProductWrapper>
          <MobileProductWrapper>
            <SliderComponent
              user={user}
              addToCart={addToCart}
              addToRequest={addToRequest}
              type="ebook"
              slides={products}
              cartItemsIds={cartItemsIds}
            />
          </MobileProductWrapper>
        </ProductCategoryWrapper>
      </>
    );
  };

  const renderBanners = () => {
    const { banners, user } = props;
    if (banners.length) {
      return <ZemplinSlider items={banners} user={user} />;
    }
    return null;
  };

  const renderH1 = () => {
    return (
      <H1Heading>
        {__('ZEMPLÍN, s.r.o. - Spojovací Materiál a Vykurovacia Technika')}
      </H1Heading>
    );
  };
  return (
    <MainWrapper>
      <MetaTags
        tags={{
          title: __(
            'ZEMPLÍN, s.r.o. - Spojovací Materiál a Vykurovacia Technika',
          ),
          description: __(`ZEMPLÍN, s.r.o. ponúka kvalitný spojovací materiál, skrutky, hmoždinky a modernú
vykurovaciu techniku. Špecialista na B2B a B2C. Rýchle dodanie a skvelé ceny!`),
          keywords: __(`ZEMPLÍN, s.r.o., spojovací materiál, skrutky, hmoždinky, vykurovacia technika, kúrenie,
B2B, B2C, montážne potreby, vykurovanie, kotly, moderné kúrenie`),
        }}
      />
      {renderH1()}
      {renderBanners()}
      {renderCategories()}
      {renderNewProducts()}
      {renderSaleProducts()}
      {renderFirstInfoBanner()}
      {renderSecondInfoBanner()}
    </MainWrapper>
  );
}

const MainWrapper = styled.div`
  max-width: ${({ theme }) => rem(theme.grid.container.wide)};
  padding-right: ${({ theme }) => rem(theme.grid.padding / 2)};
  padding-left: ${({ theme }) => rem(theme.grid.padding / 2)};
  margin: 0 auto;
  width: 100%;

  // @media screen and (max-width: 1740px) {
  //   max-width: ${rem(1080)};
  //   padding: ${rem(14)} ${rem(16)} 0;
  // }
  //
  // @media screen and (max-width: 1300px) {
  //   max-width: ${rem(960)};
  //   padding: ${rem(14)} ${rem(16)} 0;
  // }
`;

const CategoryCardsWrapper = styled(FlexRow)`
  ${({ theme }) => theme.mediab.l925`
     flex-flow: column;
     align-items: center;
  `}
`;

const ProductWrapper = styled(FlexRowCenterVertical)`
  background-color: ${({ theme }) => theme.colors.backgroundProducts};
  padding: ${rem(16)};
  border: ${({ theme }) => theme.borders.product};
  
  border-radius: 0 ${rem(4)} ${rem(4)} 0;
  width: 99%;
  justify-content: center;

  @media only screen and (min-width: 1740px) {
    max-width: ${rem(1064)};
    border-left: 0;
  }
  //
  // @media only screen and (max-width: 1300px) {
  //   max-width: ${rem(960)};
  // }

  ${({ theme }) => theme.mediab.l925`
   display: none;
  `}
`;

const H1Heading = styled.h1`
  visibility: hidden;
  height: 0;
  margin: 0;
`;

const MobileProductWrapper = styled.div`
  display: none;
  margin-right: ${rem(16)};

  ${({ theme }) => theme.mediab.l925`
   display: block;
  `}
`;

const NewProductsImageWrapper = styled(FlexColCenter)`
  width: ${rem(48)};
  height: ${rem(48)};
  padding: ${rem(14)};
  background-color: #2dcc8d;
  margin-right: ${rem(16)};
  border-radius: ${rem(2)};
`;

const SaleProductsImageWrapper = styled(NewProductsImageWrapper)`
  background-color: ${({ theme }) => theme.colors.secondary};
`;

const ProductTitleWrapper = styled(FlexRowCenterVertical)`
  margin-bottom: ${rem(24)};

  ${({ theme }) => theme.mediab.l1050`
     margin-bottom: ${rem(32)};
  `}
`;

const Title = styled(Link)`
  text-decoration: none;
  font-size: ${rem(30)};
  font-family: ${({ theme }) => theme.font.primary};
  color: ${({ theme }) => theme.colors.textPrimary};
  line-height: ${rem(38)};
  font-weight: 400;
  margin: 0;

  &:hover {
    text-decoration: underline;
  }
`;

const ProductCategoryWrapper = styled(FlexRow)`
  @media only screen and (max-width: 1740px) {
    flex-flow: column;
    align-items: left;

    margin-right: -${rem(16)};
  }
`;

const NewProductsWrapper = styled(FlexCol)`
  margin-bottom: ${rem(56)};
  margin-top: ${rem(56)};

  ${({ theme }) => theme.mediab.l925`
     margin-top: ${rem(40)};
     margin-bottom: ${rem(32)};
  `}
`;

export default withTheme(Home);
