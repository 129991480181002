import { AppThunk, RootState } from './../../rootReducer';
import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit';
import APIClass, { ThenArg } from '../../services/API';
import {
  ErrorTypeAPI,
  startFetching,
  stopFetching,
} from '../../utilities/redux';
import { saveCartCookie, loadCartCookie, removeCartCookie } from './helpers';
import { langSelector, userSelector } from '../App/selectors';
import { prop, arrayToObject } from '../../utilities';
import {
  getProductDefaultInfo,
  getElasticCategoryProductInfo,
} from 'eshop-defaults/lib/utilities/selectors';
import { createNewCart } from './utilities';
import { categoryEntitiesSelector } from '../Header/selectors';
import { categoryDataSelector } from '../Category/selectors';
import { ga4CartEvent } from '@bart.sk-ecommerce/react-online-marketing';
import { AFFILIATION_VALUE } from '../App/constants';

interface CartState {
  data: ThenArg<typeof APIClass.getCart> | null;
  error: ErrorTypeAPI;
  isFetching: boolean;
  showProblems: boolean;
  zoneId: number | null;
  deliveryPaymentInfo: {
    data: ThenArg<typeof APIClass.getDeliveryPaymentInfo> | null;
    byId: Record<
      string,
      ThenArg<typeof APIClass.getDeliveryPaymentInfo>
    > | null;
    ids: string[];
    isFetching: boolean;
    error: ErrorTypeAPI;
  };
  addToCartModal: boolean;
  addToCartModalProduct: any;
  addToCartModalIsRequest: boolean;
  addToCartModalIsFromCategory: boolean;
  wasAddedToCartModal: boolean;
  wasAddedToCartModalProduct: any;
  wasAddedToCartModalCount: number;
  wasAddedToCartModalFetching: boolean;
  wasAddedToCartModalBoughtToo: ThenArg<typeof APIClass.searchProducts> | null;
}

const initialState: CartState = {
  data: null,
  isFetching: false,
  error: null,
  showProblems: false,
  zoneId: null,
  deliveryPaymentInfo: {
    data: null,
    isFetching: false,
    error: null,
    byId: null,
    ids: [],
  },
  addToCartModal: false,
  addToCartModalProduct: null,
  addToCartModalIsRequest: false,
  addToCartModalIsFromCategory: false,
  wasAddedToCartModal: false,
  wasAddedToCartModalProduct: null,
  wasAddedToCartModalCount: 0,
  wasAddedToCartModalFetching: false,
  wasAddedToCartModalBoughtToo: null,
};

const cart = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    setShowProblems(state, action: PayloadAction<{ show: boolean }>) {
      state.showProblems = action.payload.show;
    },
    setCartZoneId(state, action: PayloadAction<{ zoneId: number | null }>) {
      state.zoneId = action.payload.zoneId;
    },
    setInitialCart(state) {
      state.data = null;
      state.error = null;
      stopFetching(state, '');
    },
    setCartProblems(state: any, action: PayloadAction<{ problems: any[] }>) {
      state.data = { ...state.data, problems: action.payload.problems };
    },
    setCreateOrderError(
      state: any,
      action: PayloadAction<{ description: string }>,
    ) {
      state.data = {
        ...state.data,
        createOrderError: action.payload.description,
      };
    },
    startFetchingCart(state) {
      startFetching(state, '');
    },
    fetchCartSuccess(
      state,
      action: PayloadAction<{
        cart: ThenArg<typeof APIClass.getCart>;
      }>,
    ) {
      state.data = action.payload.cart;
      state.error = null;
      stopFetching(state, '');
    },
    fetchCartError(state, action: PayloadAction<ErrorTypeAPI>) {
      // state.data = null;
      state.error = action.payload;
      stopFetching(state, '');
    },

    startFetchingDelPayInfo(state) {
      startFetching(state, 'deliveryPaymentInfo');
    },
    fetchDelPayInfoSuccess(
      state,
      action: PayloadAction<{
        ids: string[];
        deliveriesById: Record<
          string,
          ThenArg<typeof APIClass.getDeliveryPaymentInfo>
        >;
      }>,
    ) {
      state.deliveryPaymentInfo.ids = action.payload.ids;
      state.deliveryPaymentInfo.byId = action.payload.deliveriesById;
      stopFetching(state, 'deliveryPaymentInfo');
    },
    fetchDelPayInfoError(state, action: PayloadAction<ErrorTypeAPI>) {
      // state.data = null;
      state.error = action.payload;
      stopFetching(state, 'deliveryPaymentInfo');
    },

    setAddToCartModal(
      state,
      action: PayloadAction<{
        product?: any;
        isRequest?: boolean;
        isFromCategory?: boolean;
        showWindow?: boolean;
      }>,
    ) {
      state.addToCartModal = action.payload.showWindow
        ? true
        : action.payload.product
        ? !state.addToCartModal
        : false;
      state.addToCartModalProduct = action.payload.product || null;
      state.addToCartModalIsRequest = action.payload.isRequest || false;
      state.addToCartModalIsFromCategory =
        action.payload.isFromCategory || false;
    },

    hideAddToCartModal(state) {
      state.addToCartModal = false;
      state.addToCartModalProduct = null;
    },

    setWasAddedToCartModal(
      state,
      action: PayloadAction<{ product?: any; count?: number }>,
    ) {
      state.wasAddedToCartModal = !state.wasAddedToCartModal;
      state.wasAddedToCartModalProduct = action.payload.product || null;
      state.wasAddedToCartModalCount = action.payload.count || 0;
    },

    fetchProductInCartRecommended(state) {
      state.wasAddedToCartModalFetching = true;
    },
    setProductInCartRecommendedSuccess(
      state,
      action: PayloadAction<{
        connections: ThenArg<typeof APIClass.searchProducts>;
      }>,
    ) {
      state.wasAddedToCartModalBoughtToo = action.payload.connections;
      state['wasAddedToCartModalFetching'] = false;
    },
    setProductInCartRecommendedError(
      state,
      action: PayloadAction<ErrorTypeAPI>,
    ) {
      state.error = action.payload;
      state.wasAddedToCartModalFetching = false;
    },
  },
});

export const {
  startFetchingCart,
  fetchCartSuccess,
  fetchCartError,
  startFetchingDelPayInfo,
  fetchDelPayInfoSuccess,
  fetchDelPayInfoError,
  setAddToCartModal,
  hideAddToCartModal,
  setWasAddedToCartModal,
  fetchProductInCartRecommended,
  setProductInCartRecommendedSuccess,
  setProductInCartRecommendedError,
  setInitialCart,
  setShowProblems,
  setCartZoneId,
  setCartProblems,
  setCreateOrderError,
} = cart.actions;
export default cart.reducer;

const cartDomainSelector = (state: RootState) => state.cart;

export const cartDataSelector = createSelector(cartDomainSelector, substate =>
  prop(substate, 'data', null),
);

export const cartIdSelector = createSelector(cartDomainSelector, substate =>
  prop(substate, 'data.id', null),
);

export const cartIsFetchingSelector = createSelector(
  cartDomainSelector,
  substate => prop(substate, 'isFetching', null),
);

export const cartErrorSelector = createSelector(cartDomainSelector, substate =>
  prop(substate, 'error', null),
);

export const cartShowProblemsSelector = createSelector(
  cartDomainSelector,
  substate => prop(substate, 'showProblems', false),
);

export const cartZoneIdSelector = createSelector(cartDomainSelector, substate =>
  prop(substate, 'zoneId', false),
);

export const cartDelPayInfoIdsSelector = createSelector(
  cartDomainSelector,
  substate => prop(substate, 'deliveryPaymentInfo.ids', null),
);

export const cartDelPayInfoByIdSelector = createSelector(
  cartDomainSelector,
  substate => prop(substate, 'deliveryPaymentInfo.byId', null),
);

export const cartDelPayInfoIsFetchingSelector = createSelector(
  cartDomainSelector,
  substate => prop(substate, 'deliveryPaymentInfo.isFetching', null),
);

export const addToCartModalVisibleSelector = createSelector(
  cartDomainSelector,
  substate => prop(substate, 'addToCartModal', false),
);

export const addToCartModalProductSelector = createSelector(
  cartDomainSelector,
  substate => prop(substate, 'addToCartModalProduct', null),
);

export const addToCartModalIsRequestSelector = createSelector(
  cartDomainSelector,
  substate => prop(substate, 'addToCartModalIsRequest', false),
);

export const addToCartModalIsFromCategorySelector = createSelector(
  cartDomainSelector,
  substate => prop(substate, 'addToCartModalIsFromCategory', false),
);

export const wasAddedToCartModalProductSelector = createSelector(
  cartDomainSelector,
  substate => prop(substate, 'wasAddedToCartModalProduct', null),
);

export const wasAddedToCartModalSelector = createSelector(
  cartDomainSelector,
  substate => prop(substate, 'wasAddedToCartModal', false),
);

export const wasAddedToCartModalCountSelector = createSelector(
  cartDomainSelector,
  substate => prop(substate, 'wasAddedToCartModalCount', 0),
);

export const wasAddedToCartModalIsFetchingSelector = createSelector(
  cartDomainSelector,
  substate => prop(substate, 'wasAddedToCartModalFetching', false),
);

export const wasAddedToCartModalBoughtTooSelector = createSelector(
  cartDomainSelector,
  substate => prop(substate, 'wasAddedToCartModalBoughtToo', null),
);

export const setAddToCartModalVisibility = (
  isRequest?: boolean,
  product?: any,
  isFromCategory?: boolean,
  showWindow?: boolean,
): AppThunk => dispatch => {
  dispatch(
    setAddToCartModal({ product, isRequest, isFromCategory, showWindow }),
  );
};

export const hideAddToCartModalVisibility = (): AppThunk => dispatch => {
  dispatch(hideAddToCartModal());
};

export const setWasAddedToCartModalVisibility = (
  product?: any,
  count?: number,
): AppThunk => dispatch => {
  dispatch(setWasAddedToCartModal({ product, count }));
};

export const setCartError = (problems: any[]): AppThunk => async (
  dispatch,
  getState,
  API,
) => {
  dispatch(setCartProblems({ problems }));
};

export const setCreateOrderErrorState = (
  description: string,
): AppThunk => async (dispatch, getState, API) => {
  dispatch(setCreateOrderError({ description }));
};

export const replaceCurrentCart = (cartId: string): AppThunk => async (
  dispatch,
  getState,
  API,
) => {
  if (cartId) {
    removeCartCookie();
    saveCartCookie(cartId);
    try {
      dispatch(startFetchingCart());
      const cart = await API.getCart(cartId, { vatGroups: 1 });
      dispatch(fetchCartSuccess({ cart }));
    } catch (err) {
      dispatch(fetchCartError(err));
    }
  }
};

export const fetchCart = (
  refresh: boolean = false,
  cartCookie?: string,
  newlyGeneratedId?: string,
): AppThunk => async (dispatch, getState, API) => {
  const state = getState();
  const userCartId = prop(state, 'auth.user.cartId');
  const cookieCartId = loadCartCookie(cartCookie);

  if (
    refresh ||
    (userCartId && userCartId !== cookieCartId) ||
    !cartDataSelector(state)
  ) {
    let cartId: string | undefined =
      newlyGeneratedId || userCartId || cookieCartId;

    if (!cartId) {
      // cartId = await createNewCart(state, API);
      return;
    }

    if (cartId) {
      saveCartCookie(cartId);
      try {
        dispatch(startFetchingCart());
        const cart = await API.getCart(cartId, { vatGroups: 1 });
        dispatch(fetchCartSuccess({ cart }));
      } catch (err) {
        dispatch(fetchCartError(err));
        if (err.details.status === 404 && !newlyGeneratedId) {
          const user = userSelector(state);
          if (user) {
            const userInfo = await API.tokeninfo({ accessToken: user.token });
            if (prop(userInfo, 'cartId')) {
              dispatch(fetchCart(refresh, '', prop(userInfo, 'cartId')));
              return;
            }
          }
          const id = await createNewCart(state, API);
          removeCartCookie();
          saveCartCookie(id);
          dispatch(fetchCart(refresh, '', id));
        } else {
          dispatch(fetchCartError(err));
        }
      }
    }
  }
};

export const addItemToCartFromModal = (
  count: number = 1,
  dataLayerProducts?: any,
): AppThunk => async (dispatch, getState, API) => {
  const state = getState();
  const product = addToCartModalProductSelector(state);
  const isRequest = addToCartModalIsRequestSelector(state);
  let { productId, goodId } = getProductDefaultInfo(product);
  if (!productId || !goodId) {
    const elasticProductInfo = getElasticCategoryProductInfo(product);
    productId = elasticProductInfo.productId;
    goodId = elasticProductInfo.goodId;
  }

  let cartIdCookie = loadCartCookie();
  let cartId = prop(cartDataSelector(state), 'id');

  if (cartId && !cartIdCookie) {
    saveCartCookie(cartId);
  }
  if (!cartId) {
    cartId = await createNewCart(state, API);
  }

  try {
    dispatch(startFetchingCart());
    const cart = await API.addCartItem(
      cartId,
      goodId,
      productId,
      {
        count,
        isDemand: isRequest ? 1 : 0,
        currencyId: 'EUR',
        vatGroups: 1,
      },
      { xAcceptLanguage: langSelector(state) },
    );
    dispatch(fetchCartSuccess({ cart }));
    dispatch(hideAddToCartModalVisibility());
    const user = userSelector(state);
    if (!user || !user.b2b) {
      dispatch(setWasAddedToCartModalVisibility(product, count));
    }

    try {
      if (count > 0) {
        ga4CartEvent(
          AFFILIATION_VALUE,
          'add_to_cart',
          cart,
          product,
          dataLayerProducts,
          { quantity: count },
        );
      } else {
        ga4CartEvent(
          AFFILIATION_VALUE,
          'remove_from_cart',
          cart,
          product,
          dataLayerProducts,
          { quantity: -count },
        );
      }
    } catch (err) {
      console.error(err);
    }
  } catch (err) {
    dispatch(fetchCartError(err));
    if (window) {
      window.alert(
        prop(err, 'description', 'Nastala chyba pri pridávaní do košíka'),
      );
    }
  }
};

export const addItemToCart = (
  product: any,
  count: number = 1,
  isDemand: boolean = false,
  openModalAfter: boolean = true,
  dataLayerProducts?: any,
): AppThunk => async (dispatch, getState, API) => {
  const state = getState();
  const { productId, goodId } = getProductDefaultInfo(product);

  let cartIdCookie = loadCartCookie();
  let cartId = prop(cartDataSelector(state), 'id');

  if (cartId && !cartIdCookie) {
    saveCartCookie(cartId);
  }
  if (!cartId) {
    cartId = await createNewCart(state, API);
  }

  try {
    dispatch(startFetchingCart());
    const cart = await API.addCartItem(
      cartId,
      goodId,
      productId,
      {
        count,
        isDemand: isDemand ? 1 : 0,
        currencyId: 'EUR',
        vatGroups: 1,
      },
      { xAcceptLanguage: langSelector(state) },
    );
    dispatch(fetchCartSuccess({ cart }));
    if (openModalAfter) {
      dispatch(setAddToCartModalVisibility(isDemand, null));
      const user = userSelector(state);
      if (!user || !user.b2b) {
        dispatch(setWasAddedToCartModalVisibility(product, count));
      }
    }

    try {
      if (count > 0) {
        ga4CartEvent(
          AFFILIATION_VALUE,
          'add_to_cart',
          cart,
          product,
          dataLayerProducts,
          { quantity: count },
        );
      } else {
        ga4CartEvent(
          AFFILIATION_VALUE,
          'remove_from_cart',
          cart,
          product,
          dataLayerProducts,
          { quantity: -count },
        );
      }
    } catch (err) {
      console.error(err);
    }
  } catch (err) {
    dispatch(fetchCartError(err));

    if (window) {
      window.alert(
        prop(
          err,
          'details.description',
          'Nastala chyba pri pridávaní do košíka',
        ),
      );
    }
  }
};

export const removeItemFromCart = (
  goodId: number,
  productId: number,
  isRequest?: boolean,
  product?: any,
  dataLayerProducts?: any,
): AppThunk => async (dispatch, getState, API) => {
  const state = getState();
  const { id } = cartDataSelector(state);

  try {
    dispatch(startFetchingCart());
    const cart = await API.deleteCartItem(id, productId, goodId, {
      isDemand: isRequest ? 1 : 0,
      vatGroups: 1,
    });
    dispatch(fetchCartSuccess({ cart }));

    try {
      ga4CartEvent(
        AFFILIATION_VALUE,
        'remove_from_cart',
        cart,
        product,
        dataLayerProducts,
      );
    } catch (err) {
      console.error(err);
    }
  } catch (err) {
    dispatch(fetchCartError(err));
  }
};

export const changeItemNote = (
  goodId: number,
  note: string,
): AppThunk => async (dispatch, getState, API) => {
  const state = getState();
  const { id } = cartDataSelector(state);

  try {
    dispatch(startFetchingCart());
    const cart = await API.addItemNote(
      id,
      goodId,
      { vatGroups: 1 },
      { note },
      { xAcceptLanguage: langSelector(state) },
    );
    dispatch(fetchCartSuccess({ cart }));
  } catch (err) {
    dispatch(fetchCartError(err));
  }
};

export const changeCartNote = (note: string): AppThunk => async (
  dispatch,
  getState,

  API,
) => {
  const state = getState();
  const { id } = cartDataSelector(state);

  try {
    dispatch(startFetchingCart());
    const cart = await API.addNote(
      id,
      { vatGroups: 1 },
      { note },
      { xAcceptLanguage: langSelector(state) },
    );
    dispatch(fetchCartSuccess({ cart }));
  } catch (err) {
    dispatch(fetchCartError(err));
  }
};

export const emptyCart = (): AppThunk => async (dispatch, getState, API) => {
  const state = getState();
  const cartData = cartDataSelector(state);

  try {
    dispatch(startFetchingCart());
    const cart = await API.emptyCartItems(cartData.id, { vatGroups: 1 }, {});
    dispatch(fetchCartSuccess({ cart }));
  } catch (err) {
    dispatch(fetchCartError(err as any));
  }
};

export const updateCart = (
  data: any,
  vatGroups: boolean = true,
  setAfterUpdate: boolean = true,
): AppThunk => async (dispatch, getState, API) => {
  const state = getState();
  const cartData = cartDataSelector(state);

  try {
    if (setAfterUpdate) {
      dispatch(startFetchingCart());
    }
    const cart = await API.updateCart(
      cartData.id,
      { vatGroups: vatGroups ? 1 : 0 },
      data,
    );
    if (setAfterUpdate) {
      dispatch(fetchCartSuccess({ cart }));
    } else {
      dispatch(
        fetchCartSuccess({ cart: { ...cartData, problems: cart.problems } }),
      );
    }
  } catch (err) {
    if (setAfterUpdate) {
      dispatch(fetchCartError(err));
    }
  }
};

export const fetchDeliveryPaymentInfo = (): AppThunk => async (
  dispatch,
  getState,
  API,
) => {
  const state = getState();
  const { id } = cartDataSelector(state);

  try {
    dispatch(startFetchingDelPayInfo());
    const info = await API.getDeliveryPaymentInfo(
      id,
      {},
      { xAcceptLanguage: langSelector(state) },
    );
    const deliveriesById = arrayToObject(
      prop(info, 'delivery', []),
      'delivery_id',
    );

    const ids = prop(info, 'delivery', []).map(d => d.delivery_id);
    dispatch(fetchDelPayInfoSuccess({ ids, deliveriesById }));
  } catch (err) {
    dispatch(fetchDelPayInfoError(err));
  }
};

export const loadProductInCartRecommended = (product: any): AppThunk => async (
  dispatch,
  getState,
  API,
) => {
  try {
    dispatch(fetchProductInCartRecommended());

    let categoryId = null;
    const productCategory = prop(product, 'productCategories', [])
      ? prop(product, 'productCategories', [])[0]
      : null;
    if (productCategory) {
      categoryId = prop(productCategory, 'category_id');
    } else if (prop(categoryDataSelector(getState()), 'category_id')) {
      categoryId = prop(categoryDataSelector(getState()), 'category_id');
    } else {
      categoryId = null;
    }

    let catTotalProducts = 5;
    if (!categoryId) {
      catTotalProducts = 15000;
    } else {
      const categoriesById = categoryEntitiesSelector(getState());
      catTotalProducts = prop(categoriesById[categoryId], 'counts', 5);
    }

    const lang = langSelector(getState());
    const connections = await API.searchProducts(
      {
        limit: 4,
        categoryId: categoryId ? categoryId : '',
        offset: Math.floor(Math.random() * Math.floor(catTotalProducts)),
      },
      { xAcceptLanguage: lang },
    );
    dispatch(setProductInCartRecommendedSuccess({ connections }));
  } catch (e) {
    dispatch(setProductInCartRecommendedError(e));
  }
};

export const resetCart = (createNew = true): AppThunk => async (
  dispatch,
  getState,
  API,
) => {
  const state = getState();

  dispatch(setInitialCart());
  if (createNew) {
    await createNewCart(state, API, dispatch);
  }
};

export const showProblemsInCart = (
  show: boolean,
): AppThunk => async dispatch => {
  dispatch(setShowProblems({ show }));
};

export const setCartZone = (
  zoneId: number | null,
): AppThunk => async dispatch => {
  dispatch(setCartZoneId({ zoneId }));
};
